import { gql, useMutation, useQuery } from '@apollo/client';
import { useDispatch } from 'react-redux';
import StoryChief from '@/storychief';
import startStoryUpdate from '@/stories/actions/startStoryUpdate';
import finishStoryUpdate from '@/stories/actions/finishStoryUpdate';
import getUpdatedCustomFieldValues from '@/storychief/utils/getUpdatedCustomFieldValues';
import useModelSignKey from '@/storychief/hooks/useModelSignKey';
import usePreview from '@/storychief/hooks/usePreview';

const fragments = {
  storyCustomFields: gql`
    fragment StoryCustomFieldsFragment on Story {
      __typename
      id
      custom_field_values {
        __typename
        custom_field_id
        value
        label
        color
      }
    }
  `,
  accountCustomFields: gql`
    fragment AccountCustomFieldsFragments on Account {
      __typename
      id
      custom_fields(filter: $filter) {
        __typename
        id
        type
        name
        label
        weight
        description
        important
        rich
        multi
        options {
          __typename
          id
          custom_field_id
          value
          label
          color
        }
        dynamic_options
        visibility {
          __typename
          operator
          field_id
          field_value
        }
        locked
        option_src
        option_parent
        width
        width_operator
        height
        height_operator
        crop
        disabled_inline_styles
        disabled_block_styles
        disabled_plugins
      }
    }
  `,
  accountPreviewCustomFields: gql`
    fragment AccountPreviewCustomFieldsFragments on Account {
      __typename
      id
      custom_fields(filter: $filter) {
        __typename
        id
        type
        name
        label
        weight
        description
        important
        rich
        multi
        options {
          __typename
          id
          custom_field_id
          value
          label
          color
        }
        visibility {
          __typename
          operator
          field_id
          field_value
        }
        locked
        option_src
        option_parent
        width
        width_operator
        height
        height_operator
        crop
        disabled_inline_styles
        disabled_block_styles
        disabled_plugins
      }
    }
  `,
};

const EXTERNAL_STORY_CUSTOM_FIELDS_QUERY = gql`
  query PublicStoryCustomFields($id: ID!) {
    story: publicStory(id: $id) {
      ...StoryCustomFieldsFragment
    }
  }
  ${fragments.storyCustomFields}
`;

const INTERNAL_STORY_CUSTOM_FIELDS_QUERY = gql`
  query StoryCustomFields($id: ID!) {
    story(id: $id) {
      ...StoryCustomFieldsFragment
    }
  }
  ${fragments.storyCustomFields}
`;

const INTERNAL_ACCOUNT_CUSTOM_FIELDS_QUERY = gql`
  query AccountCustomFields($id: ID!, $filter: AccountFilterCustomFields) {
    account(id: $id) {
      ...AccountCustomFieldsFragments
    }
  }
  ${fragments.accountCustomFields}
`;

const EXTERNAL_ACCOUNT_CUSTOM_FIELDS_QUERY = gql`
  query AccountPreviewCustomFields($id: ID!, $filter: AccountFilterCustomFields) {
    account(id: $id) {
      ...AccountPreviewCustomFieldsFragments
    }
  }
  ${fragments.accountPreviewCustomFields}
`;

const UPDATE_STORY_CUSTOM_FIELDS_MUTATION = gql`
  mutation UpdateStoryCustomFields($input: UpdateCustomFieldValuesInput!) {
    story: updateCustomFieldValues(input: $input) {
      ... on Story {
        ...StoryCustomFieldsFragment
      }
    }
  }
  ${fragments.storyCustomFields}
`;

function useStoryCustomFields(storyId, isPreview = false) {
  const { preview } = usePreview();
  const dispatch = useDispatch();
  const modelSignKey = useModelSignKey({ typename: 'Story', id: storyId });

  // Queries
  const { data: { story } = {} } = useQuery(getQuery(), {
    variables: {
      id: storyId,
    },
    context: modelSignKey ? { headers: modelSignKey } : {},
    credentials: !preview,
  });

  const { data: { account } = {} } = useQuery(
    isPreview ? EXTERNAL_ACCOUNT_CUSTOM_FIELDS_QUERY : INTERNAL_ACCOUNT_CUSTOM_FIELDS_QUERY,
    {
      variables: {
        id: StoryChief.account.id,
        filter: {
          category: 'stories',
        },
      },
    },
  );
  const customFieldValues = story?.custom_field_values || [];
  const customFields = account?.custom_fields || [];
  const isCustomFieldsInitialized = story !== undefined && account !== undefined;

  // Mutations
  const [updateStoryCustomFieldsMutation] = useMutation(UPDATE_STORY_CUSTOM_FIELDS_MUTATION, {
    onCompleted() {
      dispatch(finishStoryUpdate('custom_field_values'));
    },
  });

  // Functions
  function getQuery() {
    return preview ? EXTERNAL_STORY_CUSTOM_FIELDS_QUERY : INTERNAL_STORY_CUSTOM_FIELDS_QUERY;
  }

  function updateStoryCustomFields(values) {
    dispatch(startStoryUpdate('custom_field_values'));
    updateStoryCustomFieldsMutation({
      variables: {
        input: {
          model_id: storyId,
          model_type: 'App\\Story',
          values: getUpdatedCustomFieldValues(customFields, values),
        },
      },
    });
  }

  return { customFields, customFieldValues, updateStoryCustomFields, isCustomFieldsInitialized };
}

useStoryCustomFields.fragments = fragments;

export default useStoryCustomFields;
